<template lang="pug">
main.about-page
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	.container
		left-side-section(
			title="Salon"
			:img='require("@/assets/img/final_3d_images/low/img011.jpg")'
			description=" The Salon is not only a Salon but an artist’s studio – a space where beauty is transformed into art. They paint, sculpt and at times just add a touch, all depending on the subject and the need. As artists, they observe, see, feel and understand that each one of us is unique and special and we enhance that unique personality. The artists are curators of beauty, well equipped to produce cutting-edge results with original styles and techniques. They have excelled in the art of hairstyling and make-up. We bring the expertise closer to our members to relax their mind and body and give them an experience of being light and beautiful."
		)

		full-image-section(:img='require("@/assets/img/final_3d_images/low/img004.jpg")',alt="Spa Overview")
</template>

<script>
import RightSideSection from '../components/XRightSideSection.vue'
import LeftSideSection from '../components/XLeftSideSection.vue'
import FullImageSection from '../components/XFullImageSection.vue'

export default {
	components: {
    RightSideSection,
    LeftSideSection,
	FullImageSection
  },
}
</script>

<style>
</style>